import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

import Dropdown from 'react-bootstrap/Dropdown'

export default ({ data }) => {
    const htmlFile = data.markdownRemark.fields.content;
    const slug = data.markdownRemark.fields.slug;
    const parsedSlug = parseSlug(slug);
    const solcVersion = parsedSlug[0];
    const vyperVersion = parsedSlug[1];

    const allNodes = data.allMarkdownRemark.edges.filter(node => node.node.fields !== null && typeof node.node.fields.slug !== "undefined");

    return (
        <Layout>
            <h1>Solidity {solcVersion} &amp; Vyper {vyperVersion} Cheat Sheet</h1>
            <p>This is a feature by feature reference guide to the two most popular programming languages on Ethereum.</p><br />
            <p>Something missing? Check out the official <a target="_blank" href="https://solidity.readthedocs.io">Solidity reference</a> or <a target="_blank" href="https://vyper.readthedocs.io/en/latest/">Vyper reference</a>.</p>
            <p>The content of our latest cheat sheets is open source and you are welcome to suggest changes at <a href="https://github.com/auditless/ethereum-reference">our GitHub repository</a>.</p><br />
            <h2>Looking for a different version?</h2><br />
            <ul>
                {allNodes.map(({ node }) => (
                    <li>
                        <Link to={node.fields.slug}>{renderSlugLink(node.fields.slug, slug)}</Link>
                    </li>
                ))}
            </ul><br/>
            <h2>Cheat sheet</h2><br />
            <div class="cheatsheet overflow-x-scroll md:overflow-x-visible" dangerouslySetInnerHTML={{ __html: htmlFile }}></div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            fields {
                slug
                content
            }
        }
        allMarkdownRemark {
            edges {
                node {
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

function parseSlug(slug) {
    if (slug == "/cheatsheet/") {
        return ["", ""];
    }
    const indexOfSolc = slug.indexOf("solc");
    const indexOfVyper = slug.indexOf("vyper");
    const solcVersion = slug.substring(indexOfSolc + 5, indexOfVyper - 1);
    const vyperVersion = slug.substring(indexOfVyper + 6, slug.length - 1);
    var vyperVersionFinal = ""
    if (vyperVersion[0] == 'b') {
        vyperVersionFinal = 'Beta ' + vyperVersion.substring(1, vyperVersion.length);
    } else {
        vyperVersionFinal = vyperVersion.replace(/-/g, '.');
    }
    const solcVersionFinal = solcVersion.replace(/-/g, '.');
    return [solcVersionFinal, vyperVersionFinal];
};

function renderSlugLink(slug, currentSlug) {
    var linkText;
    if (slug == "/cheatsheet/") {
        linkText = "Latest";
    } else {
        const versions = parseSlug(slug);
        const solcVersion = versions[0];
        const vyperVersion = versions[1];
        linkText = "Solidity " + solcVersion + " & Vyper " + vyperVersion;
    }
    if (slug == currentSlug) {
        linkText += " (this page)";
    }
    return linkText;
}
